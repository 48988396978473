exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-politica-de-cookies-mdx": () => import("./../../../src/pages/legal/politica-de-cookies.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-mdx" */),
  "component---src-pages-legal-politica-de-privacidade-mdx": () => import("./../../../src/pages/legal/politica-de-privacidade.mdx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidade-mdx" */),
  "component---src-pages-legal-termos-de-uso-mdx": () => import("./../../../src/pages/legal/termos-de-uso.mdx" /* webpackChunkName: "component---src-pages-legal-termos-de-uso-mdx" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-segment-js": () => import("./../../../src/templates/segment.js" /* webpackChunkName: "component---src-templates-segment-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

